import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Input } from 'antd'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import classes from './UserList.module.css'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import { GetUserListThunk, selectUsers, selectUsersFetchParams, selectUsersTotalCount, setUsersFetchParams } from '../../store/usersReducer'
import { UserListType, UsersFetchParamsType } from '../../types/usersTypes'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'
import { debounce } from 'lodash'

const UserList = () => {
  const dispatch = useAppDispatch()
  const users = useAppSelector(selectUsers)
  const usersTotalCount = useAppSelector(selectUsersTotalCount)
  const fetchParams = useAppSelector(selectUsersFetchParams)

  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetUserListThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  const setFetchParams = (params: UsersFetchParamsType) => {
    dispatch(setUsersFetchParams(params))
  }

    // eslint-disable-next-line
    const handleSearchDebounce = useCallback(
      debounce((searchRequest: string) => {
        setFetchParams({...fetchParams, search_value: encodeURI(searchRequest), pagination: {...fetchParams.pagination, page: 1}})
      }, 350),
      [fetchParams]
    )

  if (users === null) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Users
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.search_value as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
        </div>
      </div>
      <ItemList
        items={users}
        // editItemLink={editItemLink}
        // deleteItem={deleteItem}
        columnList={[]}
        loading={isDataLoading}
        pagination={fetchParams.pagination}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={usersTotalCount}
        getAdditionalColumns={getAdditionalTableColumns}
        rowIdKey='user_id'
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Full Name',
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('first_name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('first_name', column.sortOrder)
      }),
      render: (_:any, item: UserListType) => (
        `${item?.first_name || ''} ${item?.last_name || ''}`
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('email'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('email', column.sortOrder)
      }),
      render: (_:any, item: UserListType) => (
        <div className={classes.email}>
          {item?.email}
        </div>
      )
    },
    {
      title: 'Date of registration',
      dataIndex: 'registration_date',
      key: 'registration_date',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('registration_date'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('registration_date', column.sortOrder)
      }),
      render: (_:any, item: UserListType) => (
        <div>
          {moment(item?.registration_date).format('DD.MM.YYYY')}
        </div>
      )
    },
    
  ])
}

export default UserList
